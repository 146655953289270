<template>
    <div :class='[mainStore.layout_style, mainStore.menu_style]'>
        <component :is='layout' />
    </div>
</template>

<script setup>
    import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
    import { useMainStore } from '@/stores/main';
    import { useAuthStore } from '@/stores/auth';

    // Global styles, meta & composables
    import '@/assets/sass/app.scss';
    import { useMeta } from '@/composables/use-meta';
    import { showMessage } from '@/composables/utils';

    useMeta({ title: 'IT Folder' });

    // Pinia stores
    const mainStore = useMainStore();
    const authStore = useAuthStore();

    // Computed layout from main store
    const layout = computed(() => mainStore.layout);

    // Check login status from auth store
    const loggedIn = computed(() => authStore.status.loggedIn);

    // Idle timer logic
    const idleTime = ref(
        (authStore?.token?.idle_minutes || 30) * 60000 // default 30 min
    );
    let idleTimer = null; // Main idle timer
    let forceLogoutTimer = null; // Timer to force logout if user doesn't respond

    const startIdleTimer = () => {
        clearTimeout(idleTimer);
        idleTimer = setTimeout(handleIdle, idleTime.value);
    };

    const stopIdleTimer = () => {
        clearTimeout(idleTimer);
        clearTimeout(forceLogoutTimer);
    };

    const handleIdle = () => {
        if (loggedIn.value) {
            Swal.fire({
                title: 'Stay Logged on?',
                text: 'You have been inactive for a while. Do you want to stay logged in?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                clearTimeout(forceLogoutTimer);

                if (result.isConfirmed) {
                    startIdleTimer();
                } else {
                    logout();
                }
            });

            // Force logout if no response after 1 minute
            forceLogoutTimer = setTimeout(() => {
                Swal.close();
                logout();
            }, 60000);
        }
    };

    const logout = () => {
        showMessage('You have been logged out due to inactivity', 'info');
        authStore.logout();
        location.href = '/login';
    };

    const resetIdleTimer = () => {
        if (loggedIn.value) {
            startIdleTimer();
        }
    };

    // Watchers
    watch([loggedIn, idleTime], ([isLoggedIn]) => {
        if (isLoggedIn) {
            startIdleTimer();
        } else {
            stopIdleTimer();
        }
    });

    watch(
        () => authStore?.token?.idle_minutes,
        (newIdleTime) => {
            idleTime.value = (newIdleTime || 30) * 60000;
            if (loggedIn.value) {
                startIdleTimer();
            }
        }
    );

    // Lifecycle
    onMounted(() => {
        if (loggedIn.value) {
            startIdleTimer();
            document.addEventListener('mousemove', resetIdleTimer);
            document.addEventListener('mousedown', resetIdleTimer);
            document.addEventListener('keypress', resetIdleTimer);
            document.addEventListener('touchstart', resetIdleTimer);
            document.addEventListener('scroll', resetIdleTimer);
        }
    });

    onUnmounted(() => {
        stopIdleTimer();
        document.removeEventListener('mousemove', resetIdleTimer);
        document.removeEventListener('mousedown', resetIdleTimer);
        document.removeEventListener('keypress', resetIdleTimer);
        document.removeEventListener('touchstart', resetIdleTimer);
        document.removeEventListener('scroll', resetIdleTimer);
    });
</script>

<script>
    /**
     * If you are using <script setup>, you can also directly import
     * these layouts at the top. But if you prefer standard <script>,
     * you can keep them here:
     */
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';
    import publicLayout from './layouts/public-layout.vue';

    export default {
        components: {
            app: appLayout,
            auth: authLayout,
            public: publicLayout
        }
    }
</script>

// src/stores/main.js
import { defineStore } from 'pinia';
import i18n from '@/i18n'; // update as needed

export const useMainStore = defineStore('main', {
    state: () => ({
        current_layout: 'app',
        redirect_path: '/',
        is_show_sidebar: false,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'dark',
        locale: null,
        triggeredRelationshipUpdate: false,
        triggeredDeleteClick: false,
        triggeredSaveClick: false,
        triggeredPlusButtonClick: false,
        triggeredEscapeKey: false,
        currentSortBy: 'name',
        currentSortByTitle: 'Name',
        currentSortDirection: 'ascending',
        lookups: { colors: {}, icons: {}, images: {}, default_values: {} },
        cached_object: null,
        menu_style: 'collapsible-vertical',
        layout_style: 'full',
        searchParams: {},
        tempToken: {},
        countryList: [
            { code: 'en', name: 'English' },
            { code: 'es', name: 'Spanish' }
        ]
    }),
    getters: {
        layout() {
            return this.current_layout;
        },
        redirectPath() {
            return this.redirect_path;
        },
        getLookups() {
            return (field) => this.lookups[field];
        },
        getLookupValues() {
            return this.lookups;
        }
    },
    actions: {
        setLookups(params) {
            for (const key_or_id in params.value) {
                if (key_or_id === 'colors') {
                    for (const key in params.value.colors) {
                        if (!this.lookups.colors[key]) {
                            this.lookups.colors[key] = params.value.colors[key];
                            const has_default = params.value.colors[key].find((x) => x.is_default === true);
                            if (has_default && !this.lookups.default_values[key]) {
                                this.lookups.default_values[key] = has_default;
                            }
                        }
                    }
                } else if (key_or_id === 'icons') {
                    for (const key in params.value.icons) {
                        if (!this.lookups.icons[key]) {
                            this.lookups.icons[key] = params.value.icons[key];
                            const has_default = params.value.icons[key].find((x) => x.is_default === true);
                            if (has_default && !this.lookups.default_values[key]) {
                                this.lookups.default_values[key] = has_default;
                            }
                        }
                    }
                } else if (key_or_id === 'images') {
                    for (const key in params.value.images) {
                        if (!this.lookups.images[key]) {
                            this.lookups.images[key] = params.value.images[key];
                            const has_default = params.value.images[key].find((x) => x.is_default === true);
                            if (has_default && !this.lookups.default_values[key]) {
                                this.lookups.default_values[key] = has_default;
                            }
                        }
                    }
                } else {
                    for (const key in params.value) {
                        if (!this.lookups[key]) {
                            this.lookups[key] = params.value[key];
                            if (Array.isArray(params.value[key])) {
                                const has_default = params.value[key].find((x) => x.is_default === true);
                                if (has_default && !this.lookups.default_values[key]) {
                                    this.lookups.default_values[key] = has_default;
                                }
                            }
                        }
                    }
                }
            }
        },
        appendLookup(params) {
            const section = params.section;
            setTimeout(() => {
                if (section === 'colors' || section === 'icons' || section === 'images') {
                    if (!this.lookups[section][params.key]) {
                        this.lookups[section][params.key] = [];
                    }
                    this.lookups[section][params.key].push(params.value);
                } else {
                    if (!this.lookups[params.key]) {
                        this.lookups[params.key] = [];
                    }
                    this.lookups[params.key].push(params.value);
                }
            }, 500);
        },
        setSearchParams(params) {
            this.searchParams = params;
        },
        setTempToken(token) {
            this.tempToken = token;
        },
        setLayout(payload) {
            this.current_layout = payload;
        },
        setRedirectPath(payload) {
            this.redirect_path = payload;
        },
        setCachedObject(payload) {
            this.cached_object = payload;
        },
        toggleSideBar(value) {
            this.is_show_sidebar = value;
        },
        toggleSearch(value) {
            this.is_show_search = value;
        },
        toggleLocale(value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            this.locale = value;
        },
        toggleDarkMode(value) {
            value = value || 'dark';
            localStorage.setItem('dark_mode', value);
            this.dark_mode = value;
            if (value === 'light') {
                this.is_dark_mode = false;
            } else if (value === 'dark') {
                this.is_dark_mode = true;
            } else if (value === 'system') {
                this.is_dark_mode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            }
            const bodyEl = document.querySelector('body');
            if (this.is_dark_mode) bodyEl.classList.add('dark');
            else bodyEl.classList.remove('dark');
        },
        toggleMenuStyle(value) {
            value = value || '';
            localStorage.setItem('menu_style', value);
            this.menu_style = value;
            if (window.innerWidth <= 1210) {
                this.is_show_sidebar = false;
            } else {
                if (!value || value === 'vertical') {
                    this.is_show_sidebar = true;
                } else if (value === 'collapsible-vertical') {
                    this.is_show_sidebar = false;
                }
            }
        },
        toggleLayoutStyle(value) {
            value = value || '';
            localStorage.setItem('layout_style', value);
            this.layout_style = value;
        },
        setTrigger(value) {
            this.triggeredPlusButtonClick = value;
        },
        setEscapeKey(value) {
            this.triggeredEscapeKey = value;
        },
        setSaveClick(value) {
            this.triggeredSaveClick = value;
        },
        setDeleteClick(value) {
            this.triggeredDeleteClick = value;
        },
        setSortBy(value) {
            this.currentSortBy = value;
        },
        setSortByTitle(value) {
            this.currentSortByTitle = value;
        },
        setSortDirection(value) {
            this.currentSortDirection = value;
        },
        setTriggerRelationshipUpdate(value) {
            this.triggeredRelationshipUpdate = value;
        },
        updateSearchParams({ commit }, params) {
            // If you want to keep the same signature, just call the local mutation-like method
            this.setSearchParams(params);
        },
        updateTempToken(token) {
            this.setTempToken(token);
        },
        updateLookups(field, lookups) {
            this.setLookups({ value: { [field]: lookups } });
        },
        triggerPlusButton() {
            this.setTrigger(true);
            setTimeout(() => this.setTrigger(false), 100);
        },
        triggerEscapeKey() {
            this.setEscapeKey(true);
            setTimeout(() => this.setEscapeKey(false), 100);
        },
        triggerSaveClick() {
            this.setSaveClick(true);
            setTimeout(() => this.setSaveClick(false), 100);
        },
        triggerDeleteClick() {
            this.setDeleteClick(true);
            setTimeout(() => this.setDeleteClick(false), 100);
        },
        updateSortBy(value) {
            this.setSortBy(value);
        },
        updateSortByTitle(value) {
            this.setSortByTitle(value);
        },
        updateSortDirection(value) {
            this.setSortDirection(value);
        },
        updateTriggerRelationshipUpdate(value) {
            this.setTriggerRelationshipUpdate(value);
        }
    }
});

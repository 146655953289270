<template>
    <div>
        <template v-if="loggedIn">
            <!--  BEGIN NAVBAR  -->
            <Header />
            <!--  END NAVBAR  -->

            <!--  BEGIN MAIN CONTAINER  -->
            <div
                class="main-container"
                id="container"
                :class="[
          !mainStore.is_show_sidebar ? 'sidebar-closed sbar-open' : '',
          mainStore.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : ''
        ]"
            >
                <!--  BEGIN OVERLAY  -->
                <div
                    class="overlay"
                    :class="{ show: !mainStore.is_show_sidebar }"
                    @click="mainStore.toggleSideBar(!mainStore.is_show_sidebar)"
                ></div>

                <div
                    class="search-overlay"
                    :class="{ show: mainStore.is_show_search }"
                    @click="mainStore.toggleSearch(!mainStore.is_show_search)"
                ></div>
                <!-- END OVERLAY -->

                <!--  BEGIN SIDEBAR  -->
                <Sidebar />
                <!--  END SIDEBAR  -->

                <!--  BEGIN CONTENT AREA  -->
                <div id="content" class="main-content">
                    <router-view />

                    <!-- BEGIN FOOTER -->
                    <Footer />
                    <!-- END FOOTER -->
                </div>
                <!--  END CONTENT AREA  -->

            </div>
            <!--  END MAIN CONTAINER  -->
        </template>

        <template v-else>
            <div class="main-content">
                <router-view />
            </div>
        </template>
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    import { useMainStore } from '@/stores/main'
    import { useAuthStore } from '@/stores/auth'

    // Layout components
    import Header from '@/components/layout/header.vue'
    import Sidebar from '@/components/layout/sidebar.vue'
    import Footer from '@/components/layout/footer.vue'

    // Pinia stores
    const mainStore = useMainStore()
    const authStore = useAuthStore()

    // For your auth check
    const loggedIn = computed(() => authStore.status.loggedIn)
</script>

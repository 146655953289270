<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class='header-container fixed-top'>
            <header class='header navbar navbar-expand-sm'>
                <ul class='navbar-item theme-brand flex-row'>
                    <li class='nav-item theme-logo'>
                        <router-link to='/'>
                            <img src='@/assets/images/it-folder.svg' class='navbar-logo' alt='IT Folder Nav Logo' />
                        </router-link>
                    </li>
                    <li class='nav-item theme-text'>
                        <span>
                            IT Folder
                        </span>
                    </li>
                </ul>
                <div class='d-none horizontal-menu'>
                    <a href='#' class='sidebarCollapse' data-placement='bottom' aria-label='Toggle sidebar'
                       @click='mainStore.toggleSideBar(!mainStore.is_show_sidebar)'>

                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            class='feather feather-menu'
                        >
                            <line x1='3' y1='12' x2='21' y2='12'></line>
                            <line x1='3' y1='6' x2='21' y2='6'></line>
                            <line x1='3' y1='18' x2='21' y2='18'></line>
                        </svg>
                    </a>
                </div>
                <ul class='navbar-item  ms-md-0 ms-auto'>
                    <li class='nav-item align-self-center search-animated'
                        :class="{ 'show': mainStore.is_show_search }">

                        <div class='row'>
                            <div class='col-sm-7 col-12'>
                                <div class='mt-2'>
                                    <svg
                                        @click='mainStore.toggleSearch(!mainStore.is_show_search)'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='white'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        class='feather feather-search toggle-search icon'
                                        :class="{ 'input-focused': mainStore.is_show_search }"
                                    >
                                        <circle cx='11' cy='11' r='8'></circle>
                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    </svg>
                                    <form class='form-inline search-full form-inline search' @blur='clearSearch'
                                          id='global-search-form'
                                          :class="{ 'input-focused': mainStore.is_show_search }"
                                          @submit.stop.prevent='searchQuery'>
                                        <div class='search-bar'>
                                            <div class='input-group'>
                                                <label for='global-search' class='sr-only'>Search the website</label>
                                                <input type='search' v-model='search' id='global-search'
                                                       class='form-control search-form-control ms-lg-auto'
                                                       @search='searchQueryDelay'
                                                       aria-label='Search Organization'
                                                       placeholder='Search' />
                                            </div>


                                            <div class='search-container scrollable-menu m-sm-1'
                                                 :class="{'show-search': show, 'hide-search': !show}">
                                                <div class='container m-0 p-0 '>
                                                    <div class='row p-0 m-0 search-bottom' v-if='is_loaded'>
                                                        <div class='row p-0 m-0'>
                                                            <div class='col-9'>
                                                                <h4 class='mt-sm-1 mt-2'>Found ({{ total() }} items)
                                                                </h4>
                                                            </div>
                                                            <div class='col-3'>
                                                                <p class='float-end mx-sm-5 mx-4'>
                                                                    <font-awesome-icon icon='times'
                                                                                       @click='clearSearchAll'
                                                                                       class='mt-sm-3 mt-3' />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class='row p-0 m-0'
                                                         v-for='(result_obj, query_section) in resultsView'
                                                         v-if='is_loaded'>
                                                        <template v-if='loaded[query_section] && count[query_section]'>
                                                            <div class='search-bottom p-0 m-0'>
                                                                <div class='row px-1 mt-1'
                                                                     v-if='count[query_section] >= 1'>
                                                                    <div class='col-8'>
                                                                        <h5 class='mx-2'>{{ result_obj.label }}</h5>
                                                                    </div>
                                                                    <div class='col-4'>
                                                                        <div class='mt-1'>
                                                                            <a
                                                                                :href='"/" + result_obj.url + "?search_query=" + search + "&filters=" + filters'
                                                                                v-if='count[query_section] >= 1 && query_section !== "intelligent_search"'>
                                                                                All Results
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <template v-for='my_item in results[query_section]'>
                                                                    <component :is='result_obj.view' :item='my_item'
                                                                               v-if="query_section === 'devices'"
                                                                               :is_archive='is_archive'
                                                                               :color='my_item.status_color' />
                                                                    <component :is='result_obj.view' :item='my_item'
                                                                               v-else-if="query_section === 'intelligent_search'"
                                                                               :is_archive='is_archive'
                                                                    />
                                                                    <component :is='result_obj.view' :item='my_item'
                                                                               v-else-if="query_section === 'documents'"
                                                                               :is_archive='is_archive'
                                                                               :color='my_item.category_color' />
                                                                    <component :is='result_obj.view' :item='my_item'
                                                                               v-else-if="query_section === 'keys'"
                                                                               :is_archive='is_archive'
                                                                               :icon='my_item.category_icon' />
                                                                    <component :is='result_obj.view' :item='my_item'
                                                                               v-else-if="query_section === 'configurations'"
                                                                               :is_archive='is_archive'
                                                                               :color='my_item.category_color'
                                                                               :color2='my_item.environment_color' />
                                                                    <component :is='result_obj.view' :item='my_item'
                                                                               :is_archive='is_archive'
                                                                               v-else />

                                                                </template>
                                                            </div>

                                                        </template>
                                                    </div>
                                                    <div class='text-center mt-2' v-else>
                                                        <font-awesome-icon icon='spinner' spin color='white'
                                                                           size='4x' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class='col-sm-5 col-12' :class="{ 'hide-search-mobile': !mainStore.is_show_search }">


                                <div class='mx-1 search-options'
                                     :class="{ 'input-focused': mainStore.is_show_search }">
                                    <div class='btn btn-archive m-2' data-bs-toggle='tooltip' @click='checkIsAI'
                                         title='Intelligent Document Search'>
                                        <span :class="{'active': is_intelligent_search}">
                                            <font-awesome-icon icon='robot' />
                                        </span>
                                    </div>

                                    <div class='btn btn-archive m-0' data-bs-toggle='tooltip' @click='checkIsArchive'
                                         title='Is Archived'>
                                <span :class="{'active': is_archive}">
                                  <font-awesome-icon icon='box-archive' />
                                </span>
                                    </div>

                                    <button type='button' class='btn btn-outline-primary dropdown-toggle m-2'
                                            :class="{'show': show}" @click='searchQuery' :disabled='is_searching'>

                                        <font-awesome-icon icon='spinner' spin size='1x'
                                                           v-if='is_searching && !has_results' class='me-2' />
                                        Search
                                    </button>
                                </div>

                            </div>
                        </div>
                    </li>
                </ul>

                <div class='navbar-item flex-row ms-md-auto  addition-items'
                     :class="{ 'input-focused': mainStore.is_show_search }">
                    <div class='dark-mode d-flex align-items-center'>
                        <a v-if="mainStore.dark_mode === 'light'" href='#'
                           class='d-flex align-items-center'
                           @click="toggleMode('dark')">
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-sun'
                            >
                                <circle cx='12' cy='12' r='5'></circle>
                                <line x1='12' y1='1' x2='12' y2='3'></line>
                                <line x1='12' y1='21' x2='12' y2='23'></line>
                                <line x1='4.22' y1='4.22' x2='5.64' y2='5.64'></line>
                                <line x1='18.36' y1='18.36' x2='19.78' y2='19.78'></line>
                                <line x1='1' y1='12' x2='3' y2='12'></line>
                                <line x1='21' y1='12' x2='23' y2='12'></line>
                                <line x1='4.22' y1='19.78' x2='5.64' y2='18.36'></line>
                                <line x1='18.36' y1='5.64' x2='19.78' y2='4.22'></line>
                            </svg>
                        </a>
                        <a v-if="mainStore.dark_mode === 'dark'" href='#' class='d-flex align-items-center'
                           aria-label='Switch light modes'
                           @click="toggleMode('light')">
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-moon'
                            >
                                <path d='M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z'></path>
                            </svg>
                        </a>
                    </div>

                    <!--------- Notifications --------->
                    <Notifications />
                    <!--------- Notifications --------->

                    <div class='dropdown nav-item message-dropdown btn-group'>
                        <a href='javascript:' id='ddlorg' data-bs-toggle='dropdown' aria-expanded='false'
                           aria-label='View Notifications'
                           class='btn dropdown-toggle btn-icon-only nav-link'>
                            <font-awesome-icon icon='briefcase' />
                        </a>
                        <ul class='dropdown-menu dropdown-menu-left' aria-labelledby='ddlorg'
                            v-if='organizations && organizations.length === 1'>
                            <li role='presentation' v-for='organization in organizations' :key='organization.id'>
                                <a href='#' class='m-2'>{{ organization.name }} <span class='active-org'
                                                                                      v-if='organization.id === parseInt(user?.current_organization_id)'> <font-awesome-icon
                                    icon='circle' size='xs' /> </span>
                                </a>
                            </li>
                        </ul>
                        <ul class='dropdown-menu dropdown-menu-left' aria-labelledby='ddlorg'
                            v-if='organizations && organizations.length > 1'>
                            <li role='presentation' v-for='organization in organizations' :key='organization.id'>
                                <router-link :to="'/switch/' + organization.id" class='dropdown-item'
                                             aria-label='Switch Organizations'
                                >
                                    {{ organization.name }} <span class='active-org'
                                                                  v-if='organization.id === parseInt(user?.current_organization_id)'> <font-awesome-icon
                                    icon='circle' size='xs' /> </span>
                                </router-link>
                            </li>

                        </ul>
                    </div>

                    <div class='dropdown nav-item user-profile-dropdown btn-group'>
                        <a href='#' id='ddluser' data-bs-toggle='dropdown' aria-expanded='false'
                           aria-label='User Profile'
                           class='btn dropdown-toggle btn-icon-only user nav-link'>
                            <img :src='getAvatarSource()' alt='avatar' />
                        </a>
                        <ul class='dropdown-menu dropdown-menu-right' aria-labelledby='ddluser'>
                            <li role='presentation'>
                                <div v-if='user' class='dropdown-item email-div'>
                                    {{ user.email }}
                                </div>
                            </li>

                            <li role='presentation'>
                                <router-link to='/auth/profile'>
                                    <font-awesome-icon icon='user' class='mx-3' />
                                    Settings
                                </router-link>
                            </li>
                            <li role='presentation' class='mt-2'>
                                <router-link to='/auth/logout'>
                                    <font-awesome-icon icon='arrow-right-from-bracket' class='mx-3' />
                                    Sign Out
                                </router-link>
                            </li>
                        </ul>

                    </div>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class='sub-header-container'>
            <header class='header navbar navbar-expand-sm'>
                <a href='#' class='sidebarCollapse' data-placement='bottom' aria-label='Toggle sidebar'
                   @click='mainStore.toggleSideBar(!mainStore.is_show_sidebar)'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-menu'
                    >
                        <line x1='3' y1='12' x2='21' y2='12'></line>
                        <line x1='3' y1='6' x2='21' y2='6'></line>
                        <line x1='3' y1='18' x2='21' y2='18'></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id='breadcrumb' class='vue-portal-target'></div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN TOPBAR  -->
        <div class='topbar-nav header navbar' role='banner'>
            <nav class='topbar'>
                <ul class='list-unstyled menu-categories' id='topAccordion'>
                    <li class='menu single-menu'>
                        <a href='#' class='dropdown-toggle autodroprown'>
                            <div class=''>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    class='feather feather-home'
                                >
                                    <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                                    <polyline points='9 22 9 12 15 12 15 22'></polyline>
                                </svg>
                                <span>{{ $t('dashboard') }}</span>
                            </div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-down'
                            >
                                <polyline points='6 9 12 15 18 9'></polyline>
                            </svg>
                        </a>
                        <ul class='collapse submenu list-unstyled'>
                            <li>
                                <router-link to='/'> {{ $t('home') }}</router-link>
                            </li>
                        </ul>
                    </li>

                    <li class='menu single-menu'>
                        <a href='#' class='dropdown-toggle'>
                            <div class=''>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    class='feather feather-clipboard'
                                >
                                    <path
                                        d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2'></path>
                                    <rect x='8' y='2' width='8' height='4' rx='1' ry='1'></rect>
                                </svg>
                                <span>{{ $t('forms') }}</span>
                            </div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-down'
                            >
                                <polyline points='6 9 12 15 18 9'></polyline>
                            </svg>
                        </a>

                    </li>

                    <li class='menu single-menu'>
                        <a href='#' class='dropdown-toggle'>
                            <div class=''>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    class='feather feather-file'
                                >
                                    <path d='M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z'></path>
                                    <polyline points='13 2 13 9 20 9'></polyline>
                                </svg>
                                <span>{{ $t('pages') }}</span>
                            </div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-down'
                            >
                                <polyline points='6 9 12 15 18 9'></polyline>
                            </svg>
                        </a>
                        <ul class='collapse submenu list-unstyled'>
                            <li>
                                <router-link to='/pages/contact-us'>Contact Form</router-link>
                            </li>
                            <li>
                                <router-link to='/pages/faq'>FAQ</router-link>
                            </li>
                            <li>
                                <router-link to='/pages/privacy-policy'>Privacy Policy</router-link>
                            </li>
                            <li>
                                <router-link to='/users/profile'>Profile</router-link>
                            </li>
                            <li>
                                <router-link to='/users/account-setting'>Account Settings</router-link>
                            </li>
                            <li class='sub-sub-submenu-list'>
                                <a href='#' class='dropdown-toggle'>
                                    Error
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        class='feather feather-chevron-right'
                                    >
                                        <polyline points='9 18 15 12 9 6'></polyline>
                                    </svg>
                                </a>
                                <ul class='collapse list-unstyled sub-submenu'>
                                    <li><a target='_blank' href='/pages/error404'>404</a></li>
                                    <li><a target='_blank' href='/pages/error500'>500</a></li>
                                    <li><a target='_blank' href='/pages/error503'>503</a></li>
                                    <li><a target='_blank' href='/pages/maintenance'>Maintenance</a></li>
                                </ul>
                            </li>
                            <li class='sub-sub-submenu-list'>
                                <a href='#' class='dropdown-toggle'>
                                    Login
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        class='feather feather-chevron-right'
                                    >
                                        <polyline points='9 18 15 12 9 6'></polyline>
                                    </svg>
                                </a>
                                <ul class='collapse list-unstyled sub-submenu'>
                                    <li><a target='_blank' href='/auth/login'>Login</a></li>
                                </ul>
                            </li>
                            <li class='sub-sub-submenu-list'>
                                <a href='javascript:' class='dropdown-toggle'>
                                    Register
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        class='feather feather-chevron-right'
                                    >
                                        <polyline points='9 18 15 12 9 6'></polyline>
                                    </svg>
                                </a>
                                <ul class='collapse list-unstyled sub-submenu'>
                                    <li><a target='_blank' href='/auth/register'>Register</a></li>
                                </ul>
                            </li>

                            <li class='sub-sub-submenu-list'>
                                <a href='#' class='dropdown-toggle'>
                                    Password Recovery
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        class='feather feather-chevron-right'
                                    >
                                        <polyline points='9 18 15 12 9 6'></polyline>
                                    </svg>
                                </a>
                                <ul class='collapse list-unstyled sub-submenu'>
                                    <li><a target='_blank' href='/auth/password_recovery'>Password Recovery</a></li>
                                </ul>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>
        </div>
        <!--  END TOPBAR  -->
        <Shortcuts />
    </div>
</template>

<script setup>
    import { computed, onMounted, ref, shallowRef, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useMainStore } from '@/stores/main';
    import UserService from '../../services/user.service';
    import Shortcuts from '@/components/layout/shortcuts.vue';

    import DeviceAssetView from '@/views/assets/devices/search-view.vue';
    import NetworkAssetView from '@/views/assets/networks/search-view.vue';
    import SubnetAssetView from '@/views/assets/networks/subnets/search-view.vue';
    import AppView from '@/views/assets/apps/search-view.vue';
    import AppUserView from '@/views/assets/apps/app_users/search-view.vue';
    import SoftwareView from '@/views/assets/software/search-view.vue';
    import SoftwareDeviceView from '@/views/assets/software/software_assets/search-view.vue';
    import PhysicalStorageView from '@/views/assets/physical_storages/search-view.vue';
    import IPAddressAssetView from '@/views/assets/networks/subnets/ip_addresses/search-view.vue';
    import StorageView from '@/views/assets/storages/search-view.vue';
    import DataSourceView from '@/views/assets/storages/data_sources/search-view.vue';
    import DataSourcePrivilegeView from '@/views/assets/storages/data_sources/data_source_privileges/search-view.vue';
    import DataSourceShareView from '@/views/assets/storages/data_sources/data_source_shares/search-view.vue';
    import VirtualMachineView from '@/views/assets/virtual_machines/search-view.vue';

    import DocumentView from '@/views/files/documents/search-view.vue';
    import ConfigurationView from '@/views/files/configurations/search-view.vue';
    import KnowledgeBaseView from '@/views/files/knowledge_bases/search-view.vue';
    import FAQView from '@/views/files/faq/search-view.vue';

    import BudgetView from '@/views/finances/budgets/search-view.vue';
    import ExpenseView from '@/views/finances/expenses/search-view.vue';
    import ExpenseItemView from '@/views/finances/expenses/expense_items/search-view.vue';
    import LicenseView from '@/views/finances/licenses/search-view.vue';
    import LicenseSeatView from '@/views/finances/licenses/license_seats/search-view.vue';
    import ServiceView from '@/views/finances/services/search-view.vue';
    import ServiceItemView from '@/views/finances/services/service_items/search-view.vue';
    import ServiceRoleView from '@/views/finances/services/service_role_associations/search-view.vue';
    import SubscriptionsView from '@/views/finances/subscriptions/search-view.vue';
    import SubscriptionSeatView from '@/views/finances/subscriptions/subscription_seats/search-view.vue';
    import ProjectView from '@/views/finances/projects/search-view.vue';
    import CompensationView from '@/views/finances/compensations/search-view.vue';

    import LocationView from '@/views/system/organizations/locations/search-view.vue';
    import RoomView from '@/views/system/organizations/locations/rooms/search-view.vue';

    import ContactView from '@/views/people/contacts/search-view.vue';
    import EmployeeView from '@/views/people/employees/search-view.vue';
    import TrainingView from '@/views/people/employees/trainings/search-view.vue';
    import UserView from '@/views/people/users/search-view.vue';

    import ProcedureView from '@/views/procedures/procedures/search-view.vue';

    import ReportView from '@/views/reports/search-view.vue';

    import KeyView from '@/views/security/keys/search-view.vue';
    import HardeningTemplateView from '@/views/security/hardening_templates/search-view.vue';
    import IssueManagementView from '@/views/security/issue_management/search-view.vue';
    import IncidentResponseView from '@/views/security/issue_management/search-view.vue';
    import SecurityGroupView from '@/views/security/security_groups/search-view.vue';
    import SecurityGroupRuleView from '@/views/security/security_groups/security_group_rules/search-view.vue';

    import RackView from '@/views/tools/racks/search-view.vue';
    import DiagramView from '@/views/tools/diagrams/search-view.vue';
    import PhotoView from '@/views/tools/photos/search-view.vue';

    import VendorView from '@/views/third_party/vendors/search-view.vue';
    import LifecycleView from '@/views/third_party/vendors/lifecycles/search-view.vue';
    import ClientView from '@/views/third_party/clients/search-view.vue';

    import DomainView from '@/views/web_assets/domains/search-view.vue';
    import BookmarkView from '@/views/web_assets/bookmarks/search-view.vue';
    import CertificateView from '@/views/web_assets/certificates/search-view.vue';
    import IntelligentSearchView from '@/views/intelligent_search/search-view.vue';
    import { getTracking } from 'ts-tracking-number';
    import { showMessage } from '@/composables/utils';
    import Notifications from '@/components/layout/notifications.vue';

    const mainStore = useMainStore();

    // Language Support
    const selectedLang = ref(null);
    const i18n = useI18n();
    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);

        i18n.locale.value = item.code;
    };

    const organizations = ref([]);
    const search = ref(null);
    const filters = ref(null);
    const is_archive = ref(false);
    const is_intelligent_search = ref(false);
    const is_loaded = ref(false);
    const authStore = useAuthStore();
    const loggedIn = computed({
        get: () => {
            return authStore?.status?.loggedIn;
        }
    });


    const is_searching = ref(false);
    const has_results = ref(false);
    const user = computed(() => authStore?.token?.user);
    import { useRouter } from 'vue-router';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { useAuthStore } from '@/stores/auth';

    const router = useRouter();
    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };
    const show = ref(false);
    const loaded = ref({
        // Asset 14
        'devices': false,
        'networks': false,
        'subnets': false,
        'ip_addresses': false,
        'storages': false,
        'physical_storages': false,
        'data_sources': false,
        'data_source_privileges': false,
        'data_source_shares': false,
        'software': false,
        'software_assets': false,
        'apps': false,
        'app_users': false,
        'virtual_machines': false,

        // Files 4
        'configurations': false,
        'documents': false,
        'faq': false,
        'knowledge_bases': false,

        // Finances 12
        'budgets': false,
        'expenses': false,
        'expense_items': false,
        'licenses': false,
        'license_seats': false,
        'services': false,
        'service_items': false,
        'service_role_associations': false,
        'subscriptions': false,
        'subscription_seats': false,
        'projects': false,
        'compensations': false,

        // Organizations 2
        'locations': false,
        'rooms': false,

        // People 4
        'contacts': false,
        'employees': false,
        'trainings': false,
        'users': false,

        // Procedures 1
        'procedures': false,

        // Reports 1
        'reports': false,

        // Security 5
        'keys': false,
        'hardening_templates': false,
        'issue_management': false,
        'incident_responses': false,
        'security_groups': false,
        'security_group_rules': false,

        // Tools 3
        'diagrams': false,
        'photos': false,
        'racks': false,

        // Third Party 3
        'vendors': false,
        'lifecycles': false,
        'clients': false,

        // Web Assets 3
        'domains': false,
        'certificates': false,
        'bookmarks': false,

        'intelligent_search': false
    });
    const count = ref({
        // Asset 14
        'devices': 0,
        'networks': 0,
        'subnets': 0,
        'ip_addresses': 0,
        'storages': 0,
        'physical_storages': 0,
        'data_sources': 0,
        'data_source_privileges': 0,
        'data_source_shares': 0,
        'software': 0,
        'software_assets': 0,
        'apps': 0,
        'app_users': 0,
        'virtual_machines': 0,

        // Files 4
        'configurations': 0,
        'documents': 0,
        'faq': 0,
        'knowledge_bases': 0,

        // Finances 12
        'budgets': 0,
        'expenses': 0,
        'expense_items': 0,
        'licenses': 0,
        'license_seats': 0,
        'services': 0,
        'service_items': 0,
        'service_role_associations': 0,
        'subscriptions': 0,
        'subscription_seats': 0,
        'projects': 0,
        'compensations': 0,

        // Organizations 2
        'locations': 0,
        'rooms': 0,

        // People 4
        'contacts': 0,
        'employees': 0,
        'trainings': 0,
        'users': 0,

        // Procedures 1
        'procedures': 0,

        // Reports 1
        'reports': 0,

        // Security 5
        'keys': 0,
        'hardening_templates': 0,
        'issue_management': 0,
        'incident_responses': 0,
        'security_groups': 0,
        'security_group_rules': 0,

        // Tools 3
        'diagrams': 0,
        'photos': 0,
        'racks': 0,

        // Third Party 3
        'vendors': 0,
        'lifecycles': 0,
        'clients': 0,

        // Web Assets 3
        'domains': 0,
        'certificates': 0,
        'bookmarks': 0,

        'intelligent_search': 0
    });
    const results = ref({
        // Asset 14
        'devices': [],
        'networks': [],
        'subnets': [],
        'ip_addresses': [],
        'storages': [],
        'physical_storages': [],
        'data_sources': [],
        'data_source_privileges': [],
        'data_source_shares': [],
        'software': [],
        'software_assets': [],
        'apps': [],
        'app_users': [],
        'virtual_machines': [],

        // Files 4
        'configurations': [],
        'documents': [],
        'faq': [],
        'knowledge_bases': [],

        // Finances 12
        'budgets': [],
        'expenses': [],
        'expense_items': [],
        'licenses': [],
        'license_seats': [],
        'services': [],
        'service_items': [],
        'service_role_associations': [],
        'subscriptions': [],
        'subscription_seats': [],
        'projects': [],
        'compensations': [],

        // Organizations 2
        'locations': [],
        'rooms': [],

        // People 4
        'contacts': [],
        'employees': [],
        'trainings': [],
        'users': [],

        // Procedures 1
        'procedures': [],

        // Reports 1
        'reports': [],

        // Security 5
        'keys': [],
        'hardening_templates': [],
        'issue_management': [],
        'incident_responses': [],
        'security_groups': [],
        'security_group_rules': [],

        // Tools 3
        'diagrams': [],
        'photos': [],
        'racks': [],

        // Third Party 3
        'vendors': [],
        'lifecycles': [],
        'clients': [],

        // Web Assets 3
        'domains': [],
        'certificates': [],
        'bookmarks': [],

        'intelligent_search': []
    });
    const params = ref();
    const resultsView = shallowRef({
        // Asset 14
        'devices': { 'label': 'Devices', 'url': 'devices', 'view': DeviceAssetView },
        'networks': { 'label': 'Networks', 'url': 'networks', 'view': NetworkAssetView },
        'subnets': { 'label': 'Subnets', 'url': 'subnets', 'view': SubnetAssetView },
        'ip_addresses': { 'label': 'IP Addresses', 'url': 'ip_addresses', 'view': IPAddressAssetView },
        'storages': { 'label': 'Storage', 'url': 'storages', 'view': StorageView },
        'physical_storages': { 'label': 'Physical Storages', 'url': 'physical_storages', 'view': PhysicalStorageView },
        'data_sources': { 'label': 'Data Sources', 'url': 'data_sources', 'view': DataSourceView },
        'data_source_privileges': {
            'label': 'Data Source Privileges',
            'url': 'data_source_privileges',
            'view': DataSourcePrivilegeView
        },
        'data_source_shares': {
            'label': 'Data Source Shares',
            'url': 'data_source_shares',
            'view': DataSourceShareView
        },
        'software': { 'label': 'Software', 'url': 'software', 'view': SoftwareView },
        'software_assets': { 'label': 'Software Devices', 'url': 'software_assets', 'view': SoftwareDeviceView },
        'apps': { 'label': 'Apps', 'url': 'apps', 'view': AppView },
        'app_users': { 'label': 'App Users', 'url': 'app_users', 'view': AppUserView },
        'virtual_machines': { 'label': 'Virtual Machines', 'url': 'virtual_machines', 'view': VirtualMachineView },

        // Files 4
        'knowledge_bases': { 'label': 'Knowledge Bases', 'url': 'knowledge_bases', 'view': KnowledgeBaseView },
        'faq': { 'label': 'FAQ', 'url': 'faq', 'view': FAQView },
        'documents': { 'label': 'Documents', 'url': 'documents', 'view': DocumentView },
        'configurations': { 'label': 'Configurations', 'url': 'configurations', 'view': ConfigurationView },

        // Finances 12
        'budgets': { 'label': 'Budgets', 'url': 'budgets', 'view': BudgetView },
        'expenses': { 'label': 'Expenses', 'url': 'expenses', 'view': ExpenseView },
        'expense_items': { 'label': 'Expense Items', 'url': 'expense_items', 'view': ExpenseItemView },
        'services': { 'label': 'Services', 'url': 'services', 'view': ServiceView },
        'service_items': { 'label': 'Service Items', 'url': 'service_items', 'view': ServiceItemView },
        'service_role_associations': { 'label': 'Service Roles', 'url': 'service_roles', 'view': ServiceRoleView },
        'subscriptions': { 'label': 'Subscriptions', 'url': 'subscriptions', 'view': SubscriptionsView },
        'subscription_seats': {
            'label': 'Subscription Seats',
            'url': 'subscription_seats',
            'view': SubscriptionSeatView
        },
        'licenses': { 'label': 'Licenses', 'url': 'licenses', 'view': LicenseView },
        'license_seats': { 'label': 'License Seats', 'url': 'license_seats', 'view': LicenseSeatView },
        'projects': { 'label': 'Projects', 'url': 'projects', 'view': ProjectView },
        'compensations': { 'label': 'Compensations', 'url': 'compensations', 'view': CompensationView },

        // Organizations 2
        'locations': { 'label': 'Locations', 'url': 'locations', 'view': LocationView },
        'rooms': { 'label': 'Rooms', 'url': 'rooms', 'view': RoomView },

        // People 4
        'contacts': { 'label': 'Contacts', 'url': 'contacts', 'view': ContactView },
        'employees': { 'label': 'Employees', 'url': 'employees', 'view': EmployeeView },
        'trainings': { 'label': 'Trainings', 'url': 'trainings', 'view': TrainingView },
        'users': { 'label': 'Users', 'url': 'users', 'view': UserView },

        // Security 5
        'keys': { 'label': 'Keys', 'url': 'keys', 'view': KeyView },
        'hardening_templates': {
            'label': 'Hardening Templates',
            'url': 'hardening_templates',
            'view': HardeningTemplateView
        },
        'issue_management': { 'label': 'Issue Management', 'url': 'issue_managements', 'view': IssueManagementView },
        'incident_responses': {
            'label': 'Incident Responses',
            'url': 'incident_responses',
            'view': IncidentResponseView
        },
        'security_groups': { 'label': 'Security Groups', 'url': 'security_groups', 'view': SecurityGroupView },
        'security_group_rules': {
            'label': 'Security Group Rules',
            'url': 'security_group_rules',
            'view': SecurityGroupRuleView
        },

        // Procedures 1
        'procedures': { 'label': 'Procedures', 'url': 'procedures', 'view': ProcedureView },

        // Reports 1
        'reports': { 'label': 'Reports', 'url': 'reports', 'view': ReportView },

        // Tools 3
        'racks': { 'label': 'Racks', 'url': 'racks', 'view': RackView },
        'diagrams': { 'label': 'Diagrams', 'url': 'diagrams', 'view': DiagramView },
        'photos': { 'label': 'Photos', 'url': 'photos', 'view': PhotoView },

        // Third Party 3
        'vendors': { 'label': 'Vendors', 'url': 'vendors', 'view': VendorView },
        'lifecycles': { 'label': 'Lifecycles', 'url': 'lifecycles', 'view': LifecycleView },
        'clients': { 'label': 'Clients', 'url': 'clients', 'view': ClientView },

        // Web Assets 3
        'domains': { 'label': 'Domains', 'url': 'domains', 'view': DomainView },
        'certificates': { 'label': 'Certificates', 'url': 'certificates', 'view': CertificateView },
        'bookmarks': { 'label': 'Bookmarks', 'url': 'bookmarks', 'view': BookmarkView },

        'intelligent_search': {
            'label': 'Intelligent Document Search',
            'url': 'intelligent_search',
            'view': IntelligentSearchView
        }
    });

    const total = () => computed(() => {
        return Object.values(count.value).reduce((a, b) => a + b, 0);
    });

    const archiveFilter = () => {
        const filters = {};
        if (is_archive.value) {
            filters['is_archive'] = { 'enabled': true, 'option': 'none', 'value': true };
        } else {
            filters['is_archive'] = { 'enabled': true, 'option': 'none', 'value': false };
        }

        return JSON.stringify(filters);
    };

    const searchQueryDelay = (e) => {
        setTimeout(() => {
            searchQuery(e);
        }, 500);
    };

    const clearSearchAll = () => {
        clearSearch();
        has_results.value = false;
    };

    const searchQuery = async (e) => {

        // clear count values
        for (const key in count.value) {
            count.value[key] = 0;
        }

        // if already search skip
        if (is_searching.value) {
            return false;
        }

        is_searching.value = true;

        if (e) {
            e.preventDefault();
        }

        if (search.value.length === 0) {

            is_searching.value = false;
            clearSearch();
            return false;
        }

        loaded.value = Object.fromEntries(
            [
                'devices',
                'networks',
                'subnets',
                'ip_addresses',
                'storages',
                'physical_storages',
                'data_sources',
                'data_source_privileges',
                'data_source_shares',
                'software',
                'software_assets',
                'apps',
                'app_users',
                'virtual_machines',

                'configurations',
                'documents',
                'faq',
                'knowledge_bases',

                'budgets',
                'expenses',
                'expense_items',
                'licenses',
                'license_seats',
                'services',
                'service_items',
                'service_role_associations',
                'subscriptions',
                'subscription_seats',
                'projects',
                'compensations',

                'locations',
                'rooms',

                'contacts',
                'employees',
                'trainings',
                'users',

                'procedures',

                'reports',

                'keys',
                'hardening_templates',
                'issue_management',
                'incident_responses',
                'security_groups',
                'security_group_rules',

                'diagrams',
                'photos',
                'racks',

                'vendors',
                'clients',

                'domains',
                'certificates',
                'bookmarks',

                'intelligent_search'
            ].map((item) => [item, false])
        );

        params.value = {
            lookups: {
                colors: {
                    status_id: [],
                    environment_id: [],
                    category_id: []
                },
                icons: {
                    category_id: []
                }
            }
        };

        const my_filters = archiveFilter();
        filters.value = my_filters;
        is_loaded.value = false;

        if (is_intelligent_search.value) {
            await UserService.getData(`intelligent_search/?search_query=${search.value}&filters=${my_filters}`).then(async (r) => {
                show.value = true;
                loaded.value.intelligent_search = true;
                count.value.intelligent_search = r.data.items.length;
                results.value.intelligent_search = r.data.items;

            });

        } else {
            const search_data = await UserService.getData(`search/?size=2&search_query=${search.value}&filters=${my_filters}`);

            // Get key value pair
            for (const [key, value] of Object.entries(search_data.data.data)) {

                try {
                    const section = key;
                    show.value = true;
                    loaded.value[section] = true;

                    if (value.data) {  // check if r.data and r.data.items exist
                        count.value[section] = value.data.length;
                        results.value[section] = value.data;
                    } else {
                        count.value[section] = 0;  // assign 0 if r.data.items does not exist
                        results.value[section] = [];  // assign an empty array if r.data.items does not exist
                    }

                    if (section === 'expenses' || section === 'expense_items') {
                        await processExpensesExtra(value.data);
                    }
                } catch (e) {
                    console.log(e);
                }


            }
        }

        is_searching.value = false;
        is_loaded.value = true;
        has_results.value = true;
    };

    const processExpensesExtra = async (expenses) => {
        for (const item of expenses) {
            if (typeof item.tracking_number !== 'undefined' && item.tracking_number !== null) {
                const tracking_info = await getTracking(item.tracking_number);
                if (tracking_info) {
                    item.tracking_courier = tracking_info.courier.code;
                    item.tracking_url = tracking_info.trackingUrl;
                }
            }
        }
    };

    const clearSearch = () => {
        search.value = null;
        show.value = false;
    };
    const checkIsArchive = () => {
        is_archive.value = !is_archive.value;

        if (search.value) {
            searchQuery();
        }
    };

    const checkIsAI = () => {
        is_intelligent_search.value = !is_intelligent_search.value;

        if (search.value) {
            searchQuery();
        }
    };

    const getAvatarSource = () => {
        if (user?.value?.profile_url) {
            return require('@/assets/images/user_avatars/' + user.value.profile_url);
        } else {
            return require('@/assets/images/user_avatars/profile-13.svg');
        }
    };

    const triggerEscapeKey = computed(() => mainStore.triggerEscapeKey);

    watch(triggerEscapeKey, (newValue) => {
        if (newValue && is_loaded.value) {
            clearSearch();
            mainStore.setEscapeKey(false);
        }
    });


    onMounted(async () => {

        // Force Reset of search value for when the user clicks back
        search.value = null;

        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        popoverTriggerList.map((popoverTriggerEl) => {
            return new window.bootstrap.Popover(popoverTriggerEl);
        });

        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();

        if (loggedIn.value) {
            await UserService.getOrganizations().then(
                async (response) => {
                    if (typeof response !== 'undefined') {
                        organizations.value = response.data?.items;
                    }

                    if (response?.data?.error) {
                        showMessage(response.data.error, 'error');
                        await router.push('/auth/logout');
                    }

                },
                (error) => {
                    if (error.response && error.response.status === 401) {
                        const errorMessage = error.response.data.detail;
                        showMessage(errorMessage, 'error');
                    }
                }
            );
        }


    });

</script>

<style scoped>
    .scrollable-menu {
        height: auto;
        max-height: 60vh;
        overflow-x: hidden;
    }

    .status svg {
        margin: 5px 14.6px !important;
        top: unset !important;
        width: 12px !important;
        height: 12px !important;
    }

    .chevron-right svg {
        top: unset !important;
    }

    .p-header {
        font-size: 15px;
    }

    .btn-archive {
        background: #1b2e4b;
        color: #eeeef1 !important;
        fill: rgb(223, 222, 222) !important;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
        border: none;
        height: 38px;
        width: 20px;
    }

    .btn-archive svg {
        margin: 0 !important;
        position: unset !important;
    }

    .btn-archive .active svg {
        color: #04d0bd !important;
        fill: rgb(0, 150, 136) !important;
    }

    .profile {
        top: unset !important;
    }

    .active-org svg {
        color: #04d0bd !important;
        margin-top: 0;
        top: -2px !important;
        width: 10px !important;
    }

    .spin-4x {
        font-size: 1.5rem;
    }

    .search-container {
        position: absolute;
        background-color: rgb(255, 255, 255);
        top: 52px;
        z-index: 100;
        width: 100%;
        min-height: 100px;
    }

    .dark .search-container {
        background-color: rgb(26, 28, 45);
    }

    .show-search {
        display: block;
    }

    .hide-search {
        display: none;
    }

    .search-bottom {
        border-bottom: 1px solid #2c2e3e;
    }

    .active path {
        fill: #04d0bd; /* replace #desiredColor with your chosen color */
    }

    input::placeholder {
        color: white;
    }
</style>

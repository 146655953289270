// src/stores/auth.js
import { defineStore } from 'pinia'
import AuthService from '@/services/auth.service'
import api from '@/services/api'
import TokenService from '@/services/token.service'
import router from '@/router'
import { showMessage } from '@/composables/utils'
import { useMainStore } from '@/stores/main' // if you need to reference tempToken or other fields

function base64urlToUint8Array(base64url) {
    const padding = '='.repeat((4 - base64url.length % 4) % 4)
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/') + padding
    const binary = atob(base64)
    return Uint8Array.from(binary, char => char.charCodeAt(0))
}

const storedToken = JSON.parse(localStorage.getItem('token')) || null
const initialState = storedToken
    ? { loggedIn: true, needsVerification: false, token: storedToken }
    : { loggedIn: false, needsVerification: false, token: null }

export const useAuthStore = defineStore('auth', {
    state: () => ({
        status: { loggedIn: initialState.loggedIn, needsVerification: initialState.needsVerification },
        token: initialState.token
    }),
    actions: {
        login(providedToken) {
            return AuthService.login(providedToken).then(
                (respToken) => {
                    if (!respToken.user.is_mfa_enabled && !respToken.user.is_pass_key_enabled) {
                        this.status.loggedIn = true
                        this.token = respToken
                        this.status.needsVerification = false
                    } else {
                        this.status.needsVerification = true
                    }
                    return Promise.resolve(respToken)
                },
                (error) => {
                    this.status.loggedIn = false
                    this.token = null
                    return Promise.reject(error)
                }
            )
        },
        sso_login(access_token) {
            return AuthService.sso_login({ access_token }).then(
                (respToken) => {
                    this.status.loggedIn = true
                    this.token = respToken
                    return Promise.resolve(respToken)
                },
                (error) => {
                    this.status.loggedIn = false
                    this.token = null
                    return Promise.reject(error)
                }
            )
        },
        async updateToken(tok) {
            this.token = tok
        },
        async logout() {
            const current_token = TokenService.getToken()
            if (current_token?.access_token) {
                setTimeout(() => {
                    api.post('/auth/logout', {}, { headers: {
                            Authorization: 'Bearer ' + current_token.access_token,
                            'Content-Type': 'application/json'
                        } }).catch(() => false)
                }, 3000)
            }
            AuthService.logout()
            localStorage.removeItem('token')
            this.status.loggedIn = false
            this.token = null
        },
        async loginWithLink(tok) {
            this.status.loggedIn = true
            this.token = tok
        },
        register(payload) {
            return AuthService.register(payload).then(
                (response) => {
                    this.status.loggedIn = false
                    return Promise.resolve(response.data)
                },
                (error) => {
                    this.status.loggedIn = false
                    return Promise.reject(error)
                }
            )
        },
        async confirmCode(code) {
            const mainStore = useMainStore()
            const current_token = mainStore.tempToken
            const headers = {
                Authorization: 'Bearer ' + current_token.access_token,
                'Content-Type': 'application/json'
            }
            const response = await api.post('/auth/confirmCode/', code, { headers }).catch((error) => Promise.reject(error))
            if (response.data.success) {
                this.status.loggedIn = true
                this.token = response.data.data
                TokenService.setToken(response.data.data)
            }
            return response
        },
        async beginPasskey(use_header = true) {
            const mainStore = useMainStore()
            const current_token = mainStore.tempToken
            let headers = {
                Authorization: 'Bearer ' + current_token.access_token,
                'Content-Type': 'application/json'
            }
            let begin_url = '/passkey/authenticate/begin', complete_url = '/passkey/authenticate/complete'
            if (!use_header) {
                headers = { 'Content-Type': 'application/json' }
                begin_url = '/passkey/authenticate/beginLogin'
                complete_url = '/passkey/authenticate/completeLogin'
            }
            const beginResponse = await api.post(begin_url, null, { headers }).catch(async (error) => {
                if (error.response?.status === 401) {
                    showMessage('Your session has expired. Please login again.', 'error')
                    await this.logout()
                    await router.push({ path: '/auth/login' })
                }
                return Promise.reject(error)
            })
            const options = beginResponse.data
            const processedAllowCredentials = options.allowCredentials.length > 0
                ? options.allowCredentials.map(cred => ({ ...cred, id: base64urlToUint8Array(cred.id) }))
                : []
            const credential = await navigator.credentials.get({
                publicKey: {
                    ...options,
                    challenge: base64urlToUint8Array(options.challenge),
                    allowCredentials: processedAllowCredentials.length > 0 ? processedAllowCredentials : undefined,
                    userVerification: 'required'
                }
            })
            const assertionResponse = {
                id: credential.id,
                rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
                response: {
                    authenticatorData: btoa(String.fromCharCode(...new Uint8Array(credential.response.authenticatorData))),
                    clientDataJSON: btoa(String.fromCharCode(...new Uint8Array(credential.response.clientDataJSON))),
                    signature: btoa(String.fromCharCode(...new Uint8Array(credential.response.signature))),
                    userHandle: credential.response.userHandle
                        ? btoa(String.fromCharCode(...new Uint8Array(credential.response.userHandle)))
                        : null
                },
                type: credential.type,
                session_id: options?.session_id
            }
            const completeResponse = await api.post(complete_url, assertionResponse, { headers }).catch(async (error) => {
                return { data: { success: false, message: error.response?.data?.detail } }
            })
            if (completeResponse.data.success) {
                this.status.loggedIn = true
                this.token = completeResponse.data.data
                TokenService.setToken(completeResponse.data.data)
            }
            return completeResponse
        },
        confirmation(tok) {
            return AuthService.confirmation(tok)
        },
        new_user_invitation(payload) {
            return AuthService.new_user_invitation({ payload })
        },
        invitation_token_check(tok) {
            return AuthService.invitation_token_check(tok)
        },
        password_reset(payload) {
            return AuthService.password_reset({ payload })
        },
        change_password(payload) {
            return AuthService.change_password({ payload })
        },
        password_recovery_token_check(tok) {
            return AuthService.password_recovery_token_check(tok)
        },
        password_recovery(email) {
            return AuthService.password_recovery(email)
        },
        profile(payload) {
            return AuthService.profile({ payload })
        }
    }
})

<template>
    <div>
        <!-- Bootstrap 5 Modal -->
        <div class='modal fade' id='shortcutModal' tabindex='-1' aria-labelledby='modalLabel'>
            <div class='modal-dialog'>
                <div class='modal-content'>
                    <div class='modal-header'>
                        <h3 class='modal-title details-title' id='modalLabel'>Keyboard Shortcuts</h3>
                        <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                    </div>
                    <div class='modal-body'>
                        <h3 class='details-title'> Global Search</h3>
                        <ul>
                            <li>Ctrl + Q</li>
                        </ul>
                        <h3 class='details-title'>Quickly Add New Items</h3>
                        <ul>
                            <li>Ctrl + N</li>
                        </ul>
                        <h3 class='details-title'>Navigate to Page</h3>
                        <ul>
                            <li>Ctrl + H - Goes to Home Screen</li>
                            <li>Ctrl + K - Goes to Keys Page</li>
                            <li>Ctrl + D - Goes to Devices Page</li>
                            <li>Ctrl + N - Goes to Networks Page</li>
                            <li>Ctrl + P - Goes to Procedures Page</li>
                        </ul>
                        <h3 class='details-title'>In Edit Mode</h3>
                        <ul>
                            <li>Ctrl + S - Saves the form</li>
                        </ul>
                        <h3 class='details-title'>Help</h3>
                        <ul>
                            <li>Ctrl + / - Opens this modal</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, onUnmounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useMainStore } from '@/stores/main';
    
    const mainStore = useMainStore();

    const handleKeydown = event => {

        // console.log(event.key, event.ctrlKey, event.altKey, event.metaKey); // Log all key inputs to debug

        if (
            (event.ctrlKey && event.key.toLowerCase() === 'n') || (event.metaKey && event.key.toLowerCase() === 'n')
        ) {
            mainStore.triggerPlusButton();
        } else if ((event.ctrlKey && event.key.toLowerCase() === 'q') || (event.metaKey && event.key.toLowerCase() === 'q')) {
            document.getElementById('global-search').focus();
        } else if (
            (event.ctrlKey && event.key.toLowerCase() === 's') || (event.metaKey && event.key.toLowerCase() === 's')
        ) {
            mainStore.triggerSaveClick();
        } else if (
            (event.ctrlKey && event.key.toLowerCase() === 'h') || (event.metaKey && event.key.toLowerCase() === 'h')
        ) {
            router.push('/');
        } else if (
            (event.ctrlKey && event.key.toLowerCase() === 'k') || (event.metaKey && event.key.toLowerCase() === 'k')
        ) {
            checkModal();
            router.push('/keys');
        } else if (
            (event.ctrlKey && event.key.toLowerCase() === 'd') || (event.metaKey && event.key.toLowerCase() === 'd')
        ) {
            checkModal();
            router.push('/devices');
        } else if (
            (event.ctrlKey && event.key.toLowerCase() === 'p')
        ) {
            checkModal();
            router.push('/procedures');
        } else if (
            (event.ctrlKey && event.key.toLowerCase() === '/') || (event.metaKey && event.key.toLowerCase() === '/')) {
            openModal();
        } else if (event.key === 'Escape') {
            mainStore.triggerEscapeKey();
        }
    };

    const router = useRouter();

    const openModal = () => {
        if (!isModalOpen.value) {
            myModal.show();
            hasModalOpened.value = true;
        }
    };

    const checkModal = () => {
        if (myModal && hasModalOpened.value) {
            myModal.hide();
        }
    };

    let myModal = null;
    const hasModalOpened = ref(false);
    const isModalOpen = ref(false);
    onMounted(() => {
        const modalElement = document.getElementById('shortcutModal');
        if (modalElement) {
            myModal = new bootstrap.Modal(modalElement);
            modalElement.addEventListener('shown.bs.modal', () => isModalOpen.value = true);
            modalElement.addEventListener('hidden.bs.modal', () => isModalOpen.value = false);
        }
        document.addEventListener('keydown', handleKeydown);
    });
    
    onUnmounted(() => {
        document.removeEventListener('keydown', handleKeydown);
    });
</script>

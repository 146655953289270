import { useMainStore } from '@/stores/main';
import { $themeConfig } from '@themeConfig';

export default {
    init() {
        const mainStore = useMainStore();

        // Set default styles
        let val = localStorage.getItem('dark_mode'); // light, dark, system
        if (!val) {
            val = $themeConfig.theme;
        }
        mainStore.toggleDarkMode(val);

        val = localStorage.getItem('menu_style'); // vertical, collapsible-vertical, horizontal
        if (!val) {
            val = $themeConfig.navigation;
        }
        mainStore.toggleMenuStyle(val);

        val = localStorage.getItem('layout_style'); // full, boxed-layout, large-boxed-layout
        if (!val) {
            val = $themeConfig.layout;
        }
        mainStore.toggleLayoutStyle(val);

        val = localStorage.getItem('i18n_locale'); // en, es
        if (!val) {
            val = $themeConfig.lang;

            const list = mainStore.countryList;
            const item = list.find((item) => item.code === val);
            if (item) {
                this.toggleLanguage(item);
            }
        }
    },

    toggleLanguage(item) {
        const mainStore = useMainStore();

        let lang = null;
        if (item) {
            lang = item;
        } else {
            let code = mainStore.locale;
            if (!code) {
                code = localStorage.getItem('i18n_locale');
            }

            item = mainStore.countryList.find((d) => d.code === code);
            if (item) {
                lang = item;
            }
        }

        if (!lang) {
            lang = mainStore.countryList.find((d) => d.code === 'en');
        }

        mainStore.toggleLocale(lang.code);
        return lang;
    },

    toggleMode(mode) {
        const mainStore = useMainStore();

        if (!mode) {
            let val = localStorage.getItem('dark_mode'); // light, dark, system
            mode = val;
            if (!val) {
                mode = 'dark';
            }
        }
        mainStore.toggleDarkMode(mode || 'dark');
        return mode;
    },
};

import api from './api'
import { useAuthStore } from '@/stores/auth'

const catchError = (error) => {
    const defaultMessage = { data: { message: 'Error Getting Data' } }
    if (error?.response?.data?.detail) {
        if (error.response.data.detail instanceof Array) {
            const { msg, loc } = error.response.data.detail[0]
            if (msg && loc?.[1]) {
                return { data: { message: `${msg} in ${loc[1]}` } }
            }
        } else {
            return { data: { message: error.response.data.detail } }
        }
        return defaultMessage
    } else {
        return error
    }
}

// If you need to access a Pinia store in a plain .js file,
// you can define a helper to retrieve it on demand.
function getAuthStore() {
    return useAuthStore()
}

const processResponse = async (response) => {
    if (response?.data?.status) {
        if (response?.data?.status === 403) {
            // Instead of store.dispatch('auth/logout'), call Pinia's logout action
            await getAuthStore().logout()
            window.location.href = '/pages/error403'
        } else {
            return response
        }
    } else if (response?.message === 'Network Error') {
        setTimeout(() => {
            if (window.location.pathname !== '/pages/error500') {
                window.location.href = '/pages/error500'
            }
        }, 2000)
    } else {
        return response
    }
}

const buildUrl = (route, data, id, sub_section) => {
    if (!data) return route
    let url = id && sub_section ? `${route}/${id}/${sub_section}` : route
    if (url[url.length - 1] !== '/') url += '/'
    url += `?page=${data.page}`
    if (data.limit) url += `&size=${data.limit}`
    if (data.sort && data.sort.length > 0) url += `&sort=${data.sort}_${data.ascending}`
    if (data.filters) url += `&filters=${data.filters}`
    if (data.query && data.query.length > 0) url += `&search_query=${encodeURIComponent(data.query)}`
    return url
}

class UserService {
    getDecryptedConnector(id) {
        return api.get(`/connectors/decrypt/${id}`).catch(catchError).then(processResponse)
    }
    getDecryptedField(id, field, current_section = 'keys') {
        return api.get(`/${current_section}/${id}/decrypt/${field}`).catch(catchError).then(processResponse)
    }
    getDecryptedSalary(id, field) {
        return api.get(`/compensations/${id}/decrypt/${field}`).catch(catchError).then(processResponse)
    }
    getDecryptedHistoryField(id, field) {
        return api.get(`/histories/${id}/decrypt/${field}`).catch(catchError).then(processResponse)
    }
    getDecryptedLicense(id) {
        return api.get(`/licenses/${id}/decrypt/license_encrypted`).catch(catchError).then(processResponse)
    }
    getDecryptedApiKey(id) {
        return api.get(`/organizations/${id}/decrypt/api_key_encrypted`).catch(catchError).then(processResponse)
    }
    getDecryptedTaxId(id) {
        return api.get(`/organizations/${id}/decrypt/tax_id_encrypted`).catch(catchError).then(processResponse)
    }
    getDecryptedLicenseSeat(id, sid) {
        return api.get(`/licenses/${id}/license_seats/${sid}/decrypt/license_encrypted`).catch(catchError).then(processResponse)
    }
    getTOTP(id) {
        return api.get(`/keys/${id}/decrypt/totp`).catch(catchError).then(processResponse)
    }
    getData(route, data, id, sub_section) {
        const url = buildUrl(route, data, id, sub_section)
        return api.get(url, route).catch(catchError).then(processResponse)
    }
    updateData(route, data) {
        return api.put(route, data).catch(catchError).then(processResponse)
    }
    addData(route, data) {
        return api.post(route, data).catch(catchError).then(processResponse)
    }
    postData(route, data) {
        return api.post(route, data).catch(catchError).then(processResponse)
    }
    deleteData(route) {
        return api.delete(route).catch(catchError).then(processResponse)
    }
    deleteBulk(route) {
        return api.delete(route).catch(catchError).then(processResponse)
    }
    archiveBulk(route) {
        return api.put(route).catch(catchError).then(processResponse)
    }
    cloneData(route) {
        return api.post(`${route}/clone`).catch(catchError).then(processResponse)
    }
    getOrganizations() {
        return api.get('/organizations/').catch(catchError).then(processResponse)
    }
    importCSVData(route, data) {
        return api.post(route, data).catch(catchError).then(processResponse)
    }
    uploadData(route, data, headers) {
        return api.post(route, data, { headers }).catch(catchError).then(processResponse)
    }
    downloadMarkdownFiles(sectionName) {
        const route = `/download_markdown/${sectionName}/`
        return api.get(route, { responseType: 'blob' }).catch(catchError).then(processResponse)
    }
    downloadFile(fileId) {
        const route = `/files/${fileId}/`
        return api.get(route, { responseType: 'blob' }).catch(catchError).then(processResponse)
    }
}

export default new UserService()
